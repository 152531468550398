import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from 'context';
import styled from 'styled-components';

import * as D3 from 'd3';
import Pie from '../Shared/PieChart';

const AnalyticsChartSection = ({ eid }) => {
  const [showCompanies, setShowCompanies] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [professionsList, setProfessionsList] = useState([]);
  const [registereeProffessions, setRegistereeProfessions] = useState([]);
  const [registereeCompanies, setRegistereeCompanies] = useState([]);
  const [topRegistereeCompanies, setTopRegistereeCompanies] = useState([]);

  const { firebase } = useContext(FirebaseContext);
  const generatedColors = D3.quantize(
    D3.interpolateHcl('#78BE20', '#002a3a'),
    topRegistereeCompanies.length
  );

  const colors = D3.scaleOrdinal(generatedColors);

  useEffect(() => {
    let unsubscribeFromEventData;
    if (firebase) {
      unsubscribeFromEventData = firebase.moderatorDashboard.subscribeToEventData({
        eid,
        snapshot: (snapshot) => {
          if (!snapshot.empty) {
            const _companiesList = [];
            const _professionsList = [];
            snapshot.forEach((doc) => {
              const { company, profession } = doc.data();
              const formattedCompany = company.toUpperCase().replace(/[^a-zA-Z0-9 ]/, '');
              const formattedProfession = profession.toUpperCase().replace(/[^a-zA-Z0-9 ]/, '');
              if (formattedCompany.length > 0) {
                _companiesList.push(formattedCompany);
              }
              if (formattedProfession.length > 0) {
                _professionsList.push(formattedProfession);
              }
            });
            setCompaniesList(_companiesList);
            setProfessionsList(_professionsList);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventData) {
        unsubscribeFromEventData();
      }
    };
  }, [firebase]);

  useEffect(() => {
    const companyCounts = companiesList.reduce((obj, company) => {
      obj[company] = obj[company] + 1 || 1;
      return obj;
    }, {});
    Object.entries(companyCounts).forEach((array) => {
      const [company, count] = array;
      setRegistereeCompanies((currentState) => [...currentState, { label: company, value: count }]);
    });
    return () => {
      setRegistereeCompanies([]);
    };
  }, [companiesList]);

  useEffect(() => {
    const topCompanies = registereeCompanies.sort((a, b) => b.value - a.value).slice(0, 10);
    setTopRegistereeCompanies(topCompanies);
    return () => {
      setTopRegistereeCompanies([]);
    };
  }, [registereeCompanies]);

  useEffect(() => {
    const professionCounts = professionsList.reduce((obj, profession) => {
      obj[profession] = obj[profession] + 1 || 1;
      return obj;
    }, {});
    Object.entries(professionCounts).forEach((array) => {
      const [profession, count] = array;
      setRegistereeProfessions((currentState) => [
        ...currentState,
        { label: profession, value: count }
      ]);
    });
    return () => setRegistereeProfessions([]);
  }, [professionsList]);

  console.log(registereeCompanies, topRegistereeCompanies);

  return (
    <Wrapper>
      <ToggleWrapper>
        <p style={{ width: '100%' }}>Top 10 Companies</p>
      </ToggleWrapper>
      <ChartWrapper>
        <Legends>
          {showCompanies
            ? topRegistereeCompanies.map((d, index) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                  <Color style={{ background: colors(index), alignSelf: 'center' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0 0.5rem',
                      padding: '0',
                      width: '100%'
                    }}>
                    {`${d.label} (${d.value})`}
                  </p>
                </div>
              ))
            : registereeProffessions.map((d, i) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={i}>
                  <Color style={{ background: colors(i), alignSelf: 'center' }} />
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '0 0.5rem',
                      padding: 'none',
                      width: '100%'
                    }}>
                    {d.label}
                  </p>
                </div>
              ))}
        </Legends>
        <Pie
          data={showCompanies ? topRegistereeCompanies : registereeProffessions}
          width={460}
          height={460}
          innerRadius={0}
          outerRadius={230}
          cornerRadius={0}
          colors={colors}
        />
        <h3 style={{ marginTop: '4rem' }}>Other Companies</h3>
        <OtherLegends>
          {showCompanies &&
            registereeCompanies
              .filter((company) => !topRegistereeCompanies.includes(company))
              .map((d, index) => (
                <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                  <p
                    style={{
                      fontSize: '10px',
                      margin: '0 0.5rem',
                      padding: '0',
                      width: '100%'
                    }}>
                    {d.label}
                  </p>
                </div>
              ))}
        </OtherLegends>
      </ChartWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    text-transform: uppercase;
    width: 100%;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  padding: 1.175rem;
  button {
    border: none;
    border-radius: 1.875rem;
    height: 3rem;
    margin: 0 0.625rem;
    outline: none;
    width: 7.5rem;
  }
`;

const ChartWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem 4.75rem 4.75rem 4.75rem;
  @media only screen and (max-width: 799px) {
    width: 100vw;
  }
`;

const Legends = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem;
  > div {
    margin: 0 1rem 2rem 1rem;
  }
`;

const OtherLegends = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem;
  > div {
    margin: 0 1rem 1rem 1rem;
    &:nth-of-type(even) {
      color: #78be20;
    }
  }
`;

const Color = styled.div`
  height: 0.625rem;
  min-width: 2.5rem;
`;
export default AnalyticsChartSection;
