import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ProtectedRoute, Button, LoadingSpinner } from 'components';
import { FirebaseContext } from 'context';
import AnalyticsEmojiBar from '../components/Analytics/AnalyticsEmojiBar';
import AnalyticsInteractionBar from '../components/Analytics/AnalyticsInteractionsBar';
import AnalyticsChartSection from '../components/Analytics/AnalyticsChartSection';
import AnalyticsUrlSection from '../components/Analytics/AnalyticsUrlSection';

const AnalyticsDashboard = ({ pageContext }) => {
  const { eid, slug, name } = pageContext;
  const { firebase, user } = useContext(FirebaseContext);
  const [sendingReminderEmail, setSendingReminderEmail] = useState();
  return (
    <ProtectedRoute eid={eid} eventSlug={slug} isAdmin={user?.isAdmin}>
      <Wrapper>
        <p style={{ fontWeight: 'bold' }}>Analytics Dashboard</p>
        <AnalyticsBars>
          <AnalyticsInteractionBar eid={eid} />
          <AnalyticsEmojiBar eid={eid} />
        </AnalyticsBars>
        <AnalyticsChartSection eid={eid} />
        <AnalyticsUrlSection eid={eid} />
      </Wrapper>
    </ProtectedRoute>
  );
};

const Wrapper = styled(motion.div)`
  align-items: center;
  color: #1a2e59;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8.5rem;
  width: 100vw;
  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h4 {
    font-size: 3.625rem;
  }
  p {
    font-size: 1.25rem;
    text-align: center;
    width: 200px;
  }
`;

const AnalyticsBars = styled.section`
  display: flex;
  flex-direction: column;
  margin: 4.25rem 0 2.25rem 0;
  width: 100%;
`;

export default AnalyticsDashboard;
